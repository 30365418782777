export enum DataHook {
  root = 'consent-banner-root',
  description = 'consent-banner-description',
  buttons = 'consent-banner-buttons',
  viewPolicyLink = 'consent-banner-view-policy-link',
  settingsButton = 'consent-banner-settings-button',
  acceptPolicyButton = 'consent-banner-apply-button',
  declineAllButton = 'consent-banner-decline-all-button',
  settingsDialog = 'consent-banner-settings-dialog',
  closeButton = 'consent-banner-close-button',
  sectionComponentRoot = 'consent-banner-section-root',
  sectionComponentTitle = 'consent-banner-section-title',
  sectionComponentDescription = 'consent-banner-section-description',
  sectionComponentToggle = 'consent-banner-section-toggle',
  sectionComponentToggleInput = 'consent-banner-section-toggle-input',
  settingsContainer = 'consent-banner-settings-container',
  settingsSaveButton = 'consent-banner-settings-save-button',
  revisitSettingsContainer = 'consent-banner-revisit-settings-container',
  revisitSettingsButton = 'consent-banner-revisit-settings-button',
  revisitSettingsButtonText = 'consent-banner-revisit-settings-button-text',
  revisitSettingsCloseButton = 'consent-banner-revisit-settings-close-button',
}
