// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--cookie-banner-primary-color: #fff;--cookie-banner-secondary-color: #000;--cookie-banner-font-family: HelveticaNeue, Helvetica Neue, helvetica, Sans-Serif;--cookie-banner-font-size: 14px}.bYHZT6{display:none;position:fixed;top:0;left:0;max-width:195px;z-index:2147483648;text-align:center;min-height:0;overflow:hidden;pointer-events:none}.bYHZT6 .Pak4Jp{display:block;font-family:\"HelveticaNeue\",\"Helvetica Neue\",\"helvetica\",\"Sans-Serif\";font-size:14px;line-height:18px;background-color:#162d3d;padding:12px 24px 12px 24px;border:none;border-radius:8px;color:#fff}.bYHZT6 .NJ3bF4{display:inline-block;height:0;width:0;border-color:#162d3d rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0);border-style:solid;border-width:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "bYHZT6",
	"bubble": "Pak4Jp",
	"tooltipTriangle": "NJ3bF4"
};
export default ___CSS_LOADER_EXPORT___;
