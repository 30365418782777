// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--cookie-banner-primary-color: #fff;--cookie-banner-secondary-color: #000;--cookie-banner-font-family: HelveticaNeue, Helvetica Neue, helvetica, Sans-Serif;--cookie-banner-font-size: 14px}.jKUVL1{position:relative;padding:24px 90px 24px 24px;border-bottom:1px solid rgba(0,0,0,.1)}.jKUVL1:last-child{border:none}.HXQaTB{font-family:\"HelveticaNeue\",\"Helvetica Neue\",\"helvetica\",\"Sans-Serif\";font-size:16px;line-height:1.5;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;color:#000;margin:0;padding:0}.sKz7eY{font-family:\"HelveticaNeue\",\"Helvetica Neue\",\"helvetica\",\"Sans-Serif\";font-size:12px;line-height:1.33;color:#000;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;margin:12px 0 0 0;padding:0}._a6Ccw{position:absolute;right:24px;top:24px}@media(max-width: 375px){.HXQaTB{font-size:16px;line-height:22px}.sKz7eY{margin-top:6px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "jKUVL1",
	"section_title": "HXQaTB",
	"sectionTitle": "HXQaTB",
	"section_text": "sKz7eY",
	"sectionText": "sKz7eY",
	"toggle": "_a6Ccw"
};
export default ___CSS_LOADER_EXPORT___;
